import React from "react";
import "./style.scss";
import ReactPlayer from "react-player";
// import thumbnail from "../../../../assets/images/about_us_img1.webp";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function index() {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={`${BASE_URL}/storage/assets/video_2024-02-14_12-06-13.mp4`}
        width="100%"
        height="100%"
        controls
      />
    </div>
  );
}

import { useTranslation } from "react-i18next";

const useData = () => {
  const { t } = useTranslation();
  const data = {
    departments: [
      {
        id: 1,
        title: t("departments.department1.title"),
        text: t("departments.department1.text"),
      },
      {
        id: 2,
        title: t("departments.department2.title"),
        text: t("departments.department2.text"),
      },
      {
        id: 3,
        title: t("departments.department3.title"),
        text: t("departments.department3.text"),
      },
    ],
    admission: [
      {
        id: 1,
        name: t("admission.list.applicant1"),
        desc: t("admission.list.desc1"),
        status: t("admission.list.process1"),
      },
      {
        id: 2,
        name: t("admission.list.applicant2"),
        desc: t("admission.list.desc2"),
        status: t("admission.list.process2"),
      },
      {
        id: 3,
        name: t("admission.list.applicant3"),
        desc: t("admission.list.desc3"),
        status: t("admission.list.process2"),
      },
      {
        id: 4,
        name: t("admission.list.applicant1"),
        desc: t("admission.list.desc4"),
        status: t("admission.list.process2"),
      },

      {
        id: 5,
        name: t("admission.list.applicant3"),
        desc: t("admission.list.desc5"),
        status: t("admission.list.process2"),
      },
      {
        id: 6,
        name: t("admission.list.applicant3"),
        desc: t("admission.list.desc6"),
        status: t("admission.list.process2"),
      },
      {
        id: 7,
        name: t("admission.list.applicant3"),
        desc: t("admission.list.desc7"),
        status: t("admission.list.process2"),
      },
      {
        id: 8,
        name: t("admission.list.applicant3"),
        desc: t("admission.list.desc8"),
        status: t("admission.list.process2"),
      },
      {
        id: 9,
        name: t("admission.list.applicant3"),
        desc: t("admission.list.desc9"),
        status: t("admission.list.process3"),
      },
      {
        id: 10,
        name: t("admission.list.applicant4"),
        desc: t("admission.list.desc10"),
        status: t("admission.list.process4"),
      },
      {
        id: 11,
        name: t("admission.list.applicant1"),
        desc: t("admission.list.desc11"),
        status: t("admission.list.process5"),
      },
      {
        id: 12,
        name: t("admission.list.applicant3"),
        desc: t("admission.list.desc12"),
        status: t("admission.list.process6"),
      },
      {
        id: 13,
        name: t("admission.list.applicant5"),
        desc: t("admission.list.desc13"),
        status: t("admission.list.process7"),
      },
    ],
  };
  return data;
};

export default useData;

import newsBoxIcon from "../../../../assets/icons/arrow-up.svg";
import { useTranslation } from "react-i18next";

const NewsBox = ({ date, title, text }) => {
  const { t } = useTranslation();
  return (
    <div className="w-100 news-box p-sm-4 p-3 ">
      <div className="news-box__top  d-flex mb-12">
        <p className="news-box__top-title p-small pe-12 mb-0">
          {t("news.title")}
        </p>
        <p className="date p-small mb-0">{date}</p>
      </div>
      <h5 className="news-box__title lh-29 mb-12">{title}</h5>
      <div className="d-flex align-items-end">
        <p className="news-box__text p-small mb-0">{text}</p>
        <img className="news-box__icon" src={newsBoxIcon} alt="" />
      </div>
    </div>
  );
};
export default NewsBox;

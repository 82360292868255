import newsImage from "../../../../assets/images/news_img.webp";
import NewsBox from "./NewsBox";
import "./News.scss";
import ButtonPrimary from "../../../../components/ButtonPrimary/ButtonPrimary";
import { useTranslation } from "react-i18next";

const FAKE_DATA = [
  {
    id: 1,
    date: "05.12.2023",
    title: ` Qurilish sohasida ko’p yillik tajribaga ega bo'lgan “Murad Building”
    kompaniyasi tomonidan`,
    text: `Qurilish sohasida ko’p yillik tajribaga ega bo'lgan “Murad Building”
    kompaniyasi tomonidan 2023-yilda...`,
  },
  {
    id: 2,
    date: "05.12.2023",
    title: ` Eng iqtidorli talabalar uchun 10 ta maxsus "Founder's Scholarship" granti`,
    text: `Eng iqtidorli talabalar uchun 10 ta maxsus "Founder's Scholarship" granti`,
  },
  {
    id: 3,
    date: "05.12.2023",
    title: ` Qurilish sohasida ko’p yillik tajribaga ega bo'lgan “Murad Building”
    kompaniyasi tomonidan`,
    text: `Qurilish sohasida ko’p yillik tajribaga ega bo'lgan “Murad Building”
    kompaniyasi tomonidan 2023-yilda...`,
  },
];

const News = () => {
  const { t } = useTranslation();
  return (
    <section className="container news-section">
      <div className="row g-0">
        <h2 className="col-12 mb-lg-7 mb-sm-5 mb-24">{t("news.title")}</h2>
        <div className="row g-0">
          <div className="news-list col-lg-6 ps-0 pe-lg-5 mb-lg-0 mb-24">
            {FAKE_DATA.map((item) => (
              <NewsBox
                key={item.id}
                date={item.date}
                title={item.title}
                text={item.text}
              />
            ))}
          </div>

          <div className="news col-lg-6 pe-0 ps-lg-5">
            <div className=" mb-lg-7 mb-32">
              <div className="news-box__top  d-flex mb-16">
                <p className="news-box__top-title p-small pe-12 mb-0">
                  {t("news.title")}
                </p>
                <p className="date p-small mb-0">05.12.2023</p>
              </div>
              <h4 className="mb-16">
                Qurilish sohasida ko’p yillik tajribaga ega bo'lgan “Murad
                Building” kompaniyasi tomonidan
              </h4>
              <p className="mb-0">
                Murad Buildings, – bu bir xil maqsadlar va qarashlar asosida
                birlashib, bir oilaga aylanib ulgurgan, noyob, qulay va quvonch
                olib keluvchi ishonchli ko'chmas mulk ob'ektlarini , yaratish
                yo'lida...
              </p>
            </div>
            <div className="news-image mb-lg-7 mb-32">
              <img src={newsImage} alt="" className="w-100" />
            </div>
            <ButtonPrimary onClick={() => console.log("clicked!")}>
              {t("hero.button2")}
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </section>
  );
};

export default News;

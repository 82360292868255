import Hero from "./components/Hero";
import Features from "./components/Features/Features";
import Departments from "./components/Departments/Departments";
import CertificationSystem from "./components/CertificationSystem/CertificationSystem";
import News from "./components/News/News";
import PresidentCpomponent from "./components/President/PresidentComponent";
import Partners from "./components/Partners/Partners";
import SEO from "../../components/SEO";
function Index() {
  return (
    <>
  <SEO title="Bosh sahifa" description="Institutning asosiy maqsadi shaharsozlik va uy-joy kommunal xo‘jaligi sohalaridagi mutaxassislarni qayta tayyorlash va malakasini zamonaviy o‘quv dasturlari asosida oshirib borish, ularning kasb va mutaxassisliklari bo‘yicha malakasini baholash hamda davriy ravishda sertifikatlash, shuningdek, soha uchun maxsus maqsadli magistratura mutaxasisliklari bo‘yicha kadrlarni tayyorlash."/>
      <Hero />
      <Features />
      <Departments />
      <CertificationSystem />
      <News />
      <PresidentCpomponent />
      <Partners />
    </>
  );
}

export default Index;

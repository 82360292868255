import youtube from "../../assets/icons/youtube.svg";
import instagram from "../../assets/icons/instagram.svg";
import twitter from "../../assets/icons/twitter.svg";
import facebook from "../../assets/icons/facebook.svg";
import logo from "../../assets/icons/logo-h.svg";
import hamburger from "../../assets/icons/hamburger.svg";

import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { memo, useState } from "react";
import "./Header.scss";
// import Select from "react-select";

// const options = [
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" },
// ];

const Header = memo(() => {
  const { t, i18n } = useTranslation();

  const [active, setActive] = useState(false);

  const toggleMenu = () => {
    setActive(!active);
  };

  const closeMenu = () => {
    setActive(false);
  };

  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <>
      <div className="small-header bg-light2">
        <div className="small-header__wrap container padding-top-10 padding-bottom-10">
          <div className="small-header__left text-grey2 fs-12 ">
            {t("header.address")}
          </div>

          <div className="small-header__right">
            <div className="media">
              <a href="#" className="me-16">
                <img src={youtube} alt="youtube" />
              </a>
              <a href="#" className="me-16">
                <img src={twitter} alt="twitter" />
              </a>
              <a href="#" className="me-16">
                <img src={instagram} alt="instagram" />
              </a>
              <a href="#" className="me-16">
                <img src={facebook} alt="facebook" />
              </a>
            </div>

            <p className="mb-0 lh-16">
              <a href="tel:+998991234567" className="text-grey2 fs-12">
                +998 (90) 914-53-52
              </a>
            </p>

            <select className="form-select text-grey2" onClick={changeLanguage}>
              <option value="uz">Uz</option>
              <option value="ru">Ru</option>
              <option value="en">En</option>
            </select>
          </div>
        </div>
      </div>

      <nav className="navigation container d-flex align-items-center justify-content-between border-bottom border-top border-orchid2 py-16">
        <Link to="/">
          <img src={logo} alt="site logo" className="logo" />
        </Link>
        <ul
          className={`nav-menu align-items-center fs-12 ${active && "active"}`}
        >
          <li className="nav-menu__item">
            <Link to="/about" className="text-darkblack3" onClick={closeMenu}>
              {t("header.menu.link2")}
            </Link>
          </li>
          {/* <li className="nav-menu__item">
            <Select options={options} placeholder={t("header.menu.link3")} />
          </li> */}
          <li className="nav-menu__item">
            <Link
              to="/institution"
              className="text-darkblack3"
              onClick={closeMenu}
            >
              {t("header.menu.link3")}
            </Link>
          </li>
          <li className="nav-menu__item">
            <Link
              to="/admission"
              className="text-darkblack3"
              onClick={closeMenu}
            >
              {t("header.menu.link4")}
            </Link>
          </li>
          <li className="nav-menu__item">
            <Link to="/news" className="text-darkblack3" onClick={closeMenu}>
              {t("header.menu.link5")}
            </Link>
          </li>
          <li className="nav-menu__item">
            <Link to="/contact" className="text-darkblack3" onClick={closeMenu}>
              {t("header.menu.link6")}
            </Link>
          </li>
          <li className="nav-menu__item">
            <Link to="/faq" className="text-darkblack3" onClick={closeMenu}>
              {t("header.menu.link7")}
            </Link>
          </li>
        </ul>

        <div className="nav-right">
          <ButtonPrimary link="https://sert.mc.uz">
            {t("header.button")}
          </ButtonPrimary>

          <div className="hamburger-wrap" onClick={toggleMenu}>
            <img src={hamburger} alt="" />
          </div>
        </div>
      </nav>
    </>
  );
});

export default Header;

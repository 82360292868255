import "./Features.scss";
import features_img from "../../../../assets/icons/home_features_img.svg";
import { useTranslation } from "react-i18next";

const Features = () => {
  const { t } = useTranslation();
  return (
    <section className="pt-96 features container">
      <div className="row">
        <div className="features__left col-lg-6">
          <h2 className=" mb-24">{t("features.title")}</h2>
          <img src={features_img} alt="two workers pointing buildings" />
        </div>

        <div className="features__right col-lg-6 fs-20 lh-29 text-darkblack3">
          <p className="text mb-32">{t("features.feature1")}</p>
          <p className="text mb-32">{t("features.feature2")}</p>
          <p className="text mb-32">{t("features.feature3")}</p>

          <p className="text mb-32">{t("features.feature4")}</p>
        </div>
      </div>
    </section>
  );
};

export default Features;

import routes from "./route";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import { AuthProvider } from "./context/authContext";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <AuthProvider>
          <RouterProvider router={routes} />
          <ToastContainer />
        </AuthProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;

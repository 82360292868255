import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

import Root from "./pages/Root";
import Home from "./pages/Home";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
// components
const About = lazy(() => import("./pages/About"));
const Institution = lazy(() => import("./pages/Institution/Institution"));
const Admission = lazy(() => import("./pages/Admission/Admission"));
const News = lazy(() => import("./pages/News/News"));
const NewsDetails = lazy(() => import("./pages/NewsDetails/NewsDetails"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const FAQ = lazy(() => import("./pages/FAQ/FAQ"));
// admin components
const AdminLogin = lazy(() => import("./pages/AdminLogin/AdminLogin"));
const AdminPanel = lazy(() => import("./pages/AdminPanel/AdminPanel"));
const AdminNews = lazy(() => import("./pages/AdminNews/News"));
const AddNews = lazy(() => import("./pages/AdminAddNews/AddNews"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "institution",
        element: <Institution />,
      },
      {
        path: "admission",
        element: <Admission />,
      },
      {
        path: "news",
        element: <News />,
      },
      {
        path: "news/:id",
        element: <NewsDetails />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "faq",
        element: <FAQ />,
      },
    ],
  },
  {
    path: "*",
    element: <h1>Not Found</h1>,
  },
  {
    path: "/kqi-admin-login",
    id: "admin-login",
    element: <AdminLogin />,
  },
  {
    path: "/admin-panel",
    id: "admin-panel",
    element: (
      <ProtectedRoute>
        <AdminPanel />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <AdminNews />,
      },
      {
        path: "add-news",
        element: <AddNews />,
      },
      {
        path: "add-news/:id",
        element: <AddNews />,
      },
    ],
  },
]);

export default router;

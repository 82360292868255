import Header from "../components/Header";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";

const Root = () => {
  return (
    <>
      <Header />
      {<Outlet />}
      <Footer />
    </>
  );
};

export default Root;

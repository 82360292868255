import quotes from "../../../../assets/icons/quotes.svg";
import "./President.scss";
import { useTranslation } from "react-i18next";

const PresidentCpomponent = () => {
  const { t } = useTranslation();
  return (
    <section className="py-40 px-20 text-center ">
      <div
        className="section-wrapper mx-auto py-32"
        style={{ maxWidth: "1096px" }}
      >
        <h2 className="mb-24"> {t("president.title")}</h2>
        <div className="seperator row align-items-center  my-32 mx-0">
          <div className="line col h-1 "></div>
          <div className="col-auto">
            <img src={quotes} alt="" />
          </div>

          <div className="line col h-1"></div>
        </div>
        <p
          className="mb-32 text-center fs-22 fw-32"
          style={{ fontStyle: "italic" }}
        >
          {t("president.text")}
        </p>

        <div className="president-box text-center">
          <h4 className="president-box__name mb-8">
            {t("president.president_name")}
          </h4>
          <p className="president-box__position p-small mb-0">
            {t("president.president_title")}
          </p>
        </div>
      </div>
    </section>
  );
};

export default PresidentCpomponent;

// AuthContext.js
import React, { createContext, useContext, useReducer, useEffect } from "react";

const initialState = {
  user: null,
  isAuthenticated: false,
};

const ActionTypes = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
};

const authReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case ActionTypes.LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const storedUser = localStorage.getItem("kqi-access-token");

    if (storedUser) {
      const user = JSON.parse(storedUser);
      if (user && user.token && user.tokenExpiration) {
        const currentTime = new Date().getTime();
        const isTokenExpired = currentTime > user.tokenExpiration;
        if (!isTokenExpired) {
          dispatch({ type: ActionTypes.LOGIN, payload: user });
        } else {
          dispatch({ type: ActionTypes.LOGOUT });
          localStorage.removeItem("kqi-access-token");
        }
      }
    }
  }, []);
  
  const contextValue = {
    state,
    dispatch,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

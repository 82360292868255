import Accordion from "../../../../components/Accordion/Accordion";
import DepartmentsImg from "../../../../assets/images/home_departments_img.webp";
import ButtonPrimary from "../../../../components/ButtonPrimary/ButtonPrimary";
import { useTranslation } from "react-i18next";
import useData from "../../../../data";
import "./Departments.scss";

const Departments = () => {
  const data = useData();
  const { t } = useTranslation();

  return (
    <section className="mx-20 departments bg-lightblue">
      <div className="container">
        <div className="departments__details row no-gutters py-80 px-16">
          <h2 className="mb-48 col-12">{t("departments.title")}</h2>

          <div className="departments__accordion col-lg-6 pe-32">
            <ul className="custom-accordion">
              {data.departments.map(({ id, title, text }) => (
                <Accordion key={id} title={title} text={text} />
              ))}
            </ul>
            <ButtonPrimary onClick={() => {}}>
              {t("hero.button2")}
            </ButtonPrimary>
          </div>

          <div className="departments__img-wrap col-lg-6 ps-32">
            <img className="departments__img" src={DepartmentsImg} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Departments;

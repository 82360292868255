import sertificationImg from "../../../../assets/images/sertification_system.webp";

import "./CertificationSystem.scss";
import { useTranslation } from "react-i18next";

const CertificationSystem = () => {
  const { t } = useTranslation();
  return (
    <section className="certification container pt-96">
      <div className="certification__top row g-0 pt-80 pb-48">
        <h2 className="col-lg-6 pe-lg-5 ">{t("certification.title")}</h2>
        <p className="col-lg-6 ps-lg-5 ">{t("certification.introText")}</p>
      </div>

      <div className="certification__bottom row g-0 pb-80">
        <div className="certification__img-wrap col-xl-6  pe-xl-5  ps-0">
          <img loading="lazy" src={sertificationImg} alt="" className="w-100" />
        </div>
        <div className="boxes col-xl-6 ps-xl-5  pe-0 orders">
          <div className="row g-0">
            <div className="box col-12 py-sm-4 py-20 order">
              <h4 className="mb-sm-16 mb-10">
                {t("certification.article1.title")}
              </h4>
              <div className="w-100">
                <p className="p-small mb-12">
                  {t("certification.article1.text1")}
                </p>
                <p className="p-small mb-12">
                  {t("certification.article1.text2")}
                </p>
                <p className="p-small mb-0">
                  {t("certification.article1.text3")}
                </p>
              </div>
            </div>

            <div className="box col-12 py-sm-4 py-20 order">
              <h4 className="mb-sm-16 mb-10">
                {t("certification.article2.title")}
              </h4>
              <div className="w-100">
                <p className="p-small mb-12">
                  {t("certification.article2.text1")}
                </p>
                <p className="p-small mb-12">
                  {t("certification.article2.text2")}
                </p>
                <p className="p-small mb-0">
                  {t("certification.article2.text3")}
                </p>
              </div>
            </div>

            <div className="box col-12 py-sm-4 py-20 order">
              <h4 className="mb-sm-16 mb-10">
                {t("certification.article3.title")}
              </h4>
              <div className="w-100">
                <p className="p-small mb-12">
                  {t("certification.article3.text1")}
                </p>
                <p className="p-small mb-0">
                  {t("certification.article3.text2")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CertificationSystem;

import { useState } from "react";

const Accordion = ({ title, text }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openHandler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className="py-24 custom-accordion-item overflow-hidden">
      <h4 onClick={openHandler} className="title">{title}</h4>
      <div className={ `text overflow-hidden ${isOpen ? "opacity-1 open" : "opacity-0"}`}>
        <p className="p-small">
         {text}
        </p>
      </div>
    </li>
  );
};

export default Accordion;

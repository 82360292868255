import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/authContext";

function ProtectedRoute({ children }) {
  const { state } = useAuthContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!state.user) {
      navigate("/kqi-admin-login", { replace: true });
    }
  }, [navigate, state.user]);

  return children;
}

export default ProtectedRoute;

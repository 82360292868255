import "./Footer.scss";
import logo from "../../assets/icons/logo-h.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer container">
      <div className="footer__top row g-0 ">
        <h2 className="title col-12 mb-32 ">{t("footer.title")}</h2>
        <div
          className="footer__menu-box row g-0"
          style={{ marginBottom: "216px" }}
        >
          <ul className="footer-menu col-md-5 col-sm-6 pe-sm-4 pe-md-0 pb-sm-0 pb-20">
            <h4 className="mb-lg-30">{t("footer.footerMenu1.title")}</h4>
            <li>{t("footer.footerMenu1.text1")}</li>
          </ul>
          <ul className="footer-menu col-lg-5 col-sm-6 pb-sm-0 pb-20">
            <h4 className="mb-md-30">KRI</h4>
            <li
              dangerouslySetInnerHTML={{
                __html: t("footer.footerMenu2.text1").replace(/\\n/g, "<br>"),
              }}
            />
            <li>{t("footer.footerMenu2.text2")}</li>
            <li>info@kri.uz</li>
          </ul>
          <ul className="footer-menu col-lg-2 col-sm-12 pb-sm-0 pb-20">
            <h4 className="mb-md-30">{t("footer.footerMenu3.title")}</h4>
            <li>{t("footer.footerMenu3.link1")}</li>
            <li>{t("footer.footerMenu3.link2")}</li>
          </ul>
        </div>
      </div>

      <div className="row g-0 footer__bottom">
        <div className="col mb-lg-0 mb-sm-4 mb-24">
          <img src={logo} alt="" />
        </div>

        <div className="menu-wrap col-md-auto mb-80">
          <ul className="menu d-flex mb-32">
            <li>{t("header.menu.link1")}</li>
            <li>{t("header.menu.link3")}</li>
            <li>{t("header.menu.link4")}</li>
            <li>{t("header.menu.link6")}</li>
            <li>{t("header.button")}</li>
          </ul>
          <p className="fs-16">{t("footer.copyright")}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import nrg from "../../../../assets/icons/nrg.svg";
import camg from "../../../../assets/icons/camg.svg";
import koc from "../../../../assets/icons/koc.svg";
import mimar from "../../../../assets/icons/mimar.svg";
import muradBuildins from "../../../../assets/icons/murad_buildings.svg";
import tashkentCity from "../../../../assets/icons/tashkent_city.svg";
import "./Partners.scss";
import { useTranslation } from "react-i18next";

const partners = [
  {
    id: 1,
    img: nrg,
  },
  {
    id: 2,
    img: camg,
  },
  {
    id: 3,
    img: koc,
  },
  {
    id: 4,
    img: mimar,
  },
  {
    id: 5,
    img: muradBuildins,
  },
  {
    id: 6,
    img: tashkentCity,
  },
];

const Partners = () => {
  const { t } = useTranslation();

  return (
    <section
      className="partners pt-40 pb-80 mx-20 "
      style={{ backgroundColor: "#F7F9FA" }}
    >
      <div className="container" style={{ maxWidth: "1320px" }}>
        <h3 className="title mb-48">{t('partners')}</h3>
        <div className="slider">
          <div className="slide-track">
            {partners.map((partner) => (
              <div className="slide" key={partner.id}>
                <img src={partner.img} alt="" />
              </div>
            ))}
          </div>
          <div className="slide-track ms-48">
            {partners.map((partner) => (
              <div className="slide" key={partner.id}>
                <img src={partner.img} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;

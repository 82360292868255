import React from "react";
import { Link } from "react-router-dom";
import Player from "../Player";
import "./Hero.scss";
import { useTranslation } from "react-i18next";

function Index() {
  const { t } = useTranslation();
  return (
    <section>
      <div className="introduction container ">
        <div className="introduction-wrap pt-90 border-start border-orchid2 px-xxl-20">
          <div className="row">
            <div className="col-lg-7 pe-xxl-16">
              <h1 className="intro-text1 fs-36 lh-46 text-darkblack2 ">
                {t("hero.text1")}
              </h1>
            </div>
            <div className="col-lg-5">
              <p className="intro-text2 fs-20 lh-28 text-darkblack3 mb-32">
                {t("hero.text2")}
              </p>
              <div className="d-flex">
                <Link
                  to={"/"}
                  style={{ width: "50%" }}
                  className="btn rounded-0 bg-darkblue2 text-white me-16 py-16 fs-12 fw-500"
                >
                  {t("hero.button1")}
                </Link>
                <Link
                  to={"/"}
                  style={{ width: "50%" }}
                  className="btn rounded-0 text-darkblue2 border border-darkblue2 py-16 fs-12 fw-500"
                >
                  {t("hero.button2")}
                </Link>
              </div>
            </div>
          </div>
          <div className="custom-player mt-96">
            <Player />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;

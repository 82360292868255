import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./Locales/en.json";
import ru from "./Locales/ru.json";
import uz from "./Locales/uz.json";

const resources = {
  en: { translation: en },
  ru: { translation: ru },
  uz: { translation: uz },
  // Other languages
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "uz",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;